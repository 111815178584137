import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

import { selectLanguage, transformLinks } from "../interface/api-to-component"
import { 
	PRISMIC_GLOBAL_LINKS_INDEX,
  PRISMIC_SERVICE_LINKS_INDEX,
} from "../utils/prismic"
//import { Menu } from "@mrmaru/lucilles-components/dist/index"
import Menu from "./menu/menu"
import Footer from "./footer"
import "./layout.css"

import Image from "../components/image-logo-lucilles"

import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false;

const Main = styled.main`
  max-width: 1000px;
  margin 0 10px;
  padding: 40px 0 0 0;
  @media (min-width: 768px) {
    padding: 100px 0 0 0;
  }
  @media (min-width: 1024px) {
    margin: auto;
  }
  @media (min-width: 1200px) {
    padding: 200px 0 0 0;
  }
`
const Layout = ({ 
  children, 
  data, 
  lang, 
  page_links,
  social_links,
  call_to_action_label,
  call_to_action_link 
}) => {

  if(!data.prismic.allGlobal_links.edges[0])
    return null
	const links = selectLanguage(lang, data.prismic.allGlobal_links.edges)
  const global_links = transformLinks(links, PRISMIC_GLOBAL_LINKS_INDEX)
  const service_links = transformLinks(links, PRISMIC_SERVICE_LINKS_INDEX)
  const locale = lang === 'fr-ca' ? 'fr' : 'en'
  return (
    <>
      <Menu 
        lang={locale}
        page_links={page_links[locale]} 
        social_links={social_links} 
        global_links={global_links} 
        image={Image} 
      />
      <Main>{children}</Main>
      <Footer 
        lang={lang} 
        global_links={global_links}
        service_links={service_links}
        call_to_action_label={call_to_action_label}
        call_to_action_link={call_to_action_link}
      />
    </>
  )
}

export default props => (
	<StaticQuery
		query={graphql`
			query SiteLayoutQuery {
				prismic {
					allGlobal_links {
						edges {
							node {
								body {
									... on PRISMIC_Global_linkBodyLinks {
										fields {
											label
											link {
												... on PRISMIC__ExternalLink {
													url
												}
											}
										}
									}
								}
								_meta {
									lang
								}
							}
						}
					}
				}
			}
		`}
		render={ data => <Layout data={data} {...props} />}
	/>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
